import React from 'react'
import './About.css'

const About = () => {
  return (
    <section className='about'>
        <header>
            <h1>
                About Us 
            </h1>
        </header>
        <article>
            <p>
           <span>FirstCoteworld</span>  Megabiz Limited is a duly registered company with Corporate Affairs Commission (CAC) of Nigeria since 2017. It is also registered with the Nigerian Export Promotion Council (NEPC) and the Federal Ministry of Environment as an Environmental Consultancy Firm with specialty in Environmental Impact Assessment (EIA), Waste Management, Oil Spill and Bioremediation etc.  We also operate an organic farm as one of our future subsidiaries that specializes in Poultry, Piggery, Crop farming, Fishery, etc. We also assist some of our partners and clients in empowering the unemployed youths in Nigeria through Agribusiness training, Vocational and other entrepreneurial development projects as part of our corporate social responsibility (CSR) through Care of the Estranged for Social Development (COTE) and Centre for Learning and Service Foundation (CeLSF). As a fully registered Export company, we look forward to becoming one of the leading exporters of organic agricultural and non-oil products within Nigeria and across the world in the nearest future.
            </p>
        </article>
    </section>
  )
}

export default About