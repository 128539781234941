import './App.css';
import Landing from './pages/Landing/Landing';
import { useEffect, useState } from 'react';

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleMediaChange = (mediaQueryList) => {
      setIsSmallScreen(mediaQueryList.matches);
    };

    const mediaQueryList = window.matchMedia('(max-width: 600px)');

    handleMediaChange(mediaQueryList);
    mediaQueryList.addListener(handleMediaChange);
    return () => {
      mediaQueryList.removeListener(handleMediaChange);
    };
    
  }, []);
  return (
    <div>
      {isSmallScreen ? <Landing/>: 'Still in progress'  }
      
    </div>
  );
}

export default App;
