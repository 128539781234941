import React from 'react'
import './Offer.css'

const Offer = () => {
  return (
    <section className='offer'>
        <header>
            <hr />
            <h1>
                What We Offer 
            </h1>
        </header>
        <ul>
            <li>Environmental Consultancy</li>
            <li>Business Advisory Services</li>
        </ul>
        <ul>
            <li>Export of Agricultural and Non-Oil Products</li>
            <li>Sale of safe and quality organic food products</li>
        </ul>
        <ul>
            <li>Training and consultancy in organic agriculture and food safety</li>
        </ul>
    </section>
  )
}

export default Offer