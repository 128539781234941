import React from 'react'
import './Landing.css'
import Nav from '../../components/Nav/Nav'
import Hero from '../../components/Hero/Hero'
import About from '../../components/About/About'
import Missionvision from '../../components/missionvision/missionvision'
import Subs from '../../components/Subs/Subs'
import Coll from '../../components/Coll/Coll'
import Offer from '../../components/Offer/Offer'
import Achievements from '../../components/Achievements/Achievements'
import Footer from '../../components/Footer/footer'

const Landing = () => {
  return (
    <div className='landing'>
        <Nav/>
        <Hero/> 
        <About/>
        <Missionvision/> 
        <Subs/>
        <Coll/>
        <Offer/>
        <Achievements/>
        <Footer/>
    </div>
  )
}

export default Landing