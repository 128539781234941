import React from 'react'
import './Nav.css'

const Nav = () => {
  return (
    <nav>
        <img src="/images/logo (1).jpg" alt="" />
        <ul>
            <li>
                Menu 
            </li>
        </ul>
    </nav>
  )
}

export default Nav