import React from 'react'
import './Coll.css'

const Coll = () => {
    return (
        <section className='coll'>
            <header>
                <hr />
                <h1>
                    Collaborations
                </h1>
            </header>
            <ul>
                <li><a href="/">Centre for Learning and Service Foundation (CeLSF)</a></li>
                <li><a href="/">Life After School Project (LASP)</a></li>
                <li><a href="/">Coteworld Volunteers Forum (CVF)</a></li>
            </ul>
        </section>
    )
}

export default Coll