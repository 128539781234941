import React from 'react'
import './Hero.css'

const Hero = () => {
  return (
    <section className='hero'>
        <article>
            <h1>
                <span>Firstcoteworld...</span> <br /> Imparting Lives by Doing Good
            </h1>
            <button>
                Learn more 
            </button>
        </article>
        <main>
            <img src="/images/WP_20180321_12_50_18_Pro.jpg" alt="" />
            <img src="/images/WP_20170727_12_20_18_Pro.jpg" alt="" />
            <img src="/images/WP_20180321_11_22_51_Pro.jpg" alt="" />
        </main>
    </section>
  )
}

export default Hero