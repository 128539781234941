import React from 'react'
import './Achievements.css'

const Achievements = () => {
  return (
    <section className='achieve'>
        <header>
            <hr />
            <h1>
                Achievements 
            </h1>
        </header>
        <main>
            <div>
                <img src="/images/ach.png" alt="" />
                <p>
                Over 5,000 youths and adults have participated in our free healthcare delivery services and free
                entrepreneurial classes for some years now.
                </p>
            </div>
            <div>
                <img src="/images/ach.png" alt="" />
                <p>
                Has trained and taught over 25 students as Interns on Industrial Attachment to the company.
                </p>
            </div>
            <div>
                <img src="/images/ach.png" alt="" />
                <p>
                Operates existing organic farms on poultry, piggery and crop farming etc.
                </p>
            </div>
            <div>
                <img src="/images/ach.png" alt="" />
                <p>
                Operates a training centre; where youths especially undergraduates upgrade their agricultural, vocational and entrepreneurial skills within University of Nigeria, Nsukka Campus.
                </p>
            </div>
        </main>
    </section>
  )
}

export default Achievements