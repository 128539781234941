import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer style={{marginTop: '6rem'}}>
        <hr />
        <div className='footer'>
        <div>
            <h1>
            FIRSTCOTEWORLD
            </h1>
            <ul>
                <li><a href="/"><img src="/images/mail.png" alt="" /></a></li>
                <li><a href="/"><img src="/images/fb.png" alt="" /></a></li>
                <li><a href="/"><img src="/images/yt.png" alt="" /></a></li>
            </ul>
        </div>
        <div>
            Copyrights reserved <img src="/images/copyr.png" alt="" /> 2023
        </div>
        </div>
        
    </footer>
  )
}

export default Footer