import React from 'react'
import './Subs.css'

const Subs = () => {
    return (
        <section className='subs'>
            <header>
                <hr />
                <h1>
                    Subsidaries
                </h1>
            </header>
            <ul>
                <li>
                    <img src="/images/list1.png" alt="" /> <span>
                    Coteworld Enterprise ( coordinates Coteworld Farms and our agro-processing activities)</span>
                </li>
                <li>
                    <img src="/images/list1.png" alt="" /> <span>
                    Care of the Estranged for Social Development (COTE)
                    </span>
                </li>
                <li>
                    <img src="/images/list1.png" alt="" /> <span>
                    Coteworld Multipurpose Cooperative Society (coordinates youths’ savings and thrifts for future businesses). </span>
                </li>
                <li>
                    <img src="/images/list1.png" alt="" /> <span>
                    OG. Ventures (coordinates our food sales outlets) </span>
                </li>
            </ul>
        </section>
    )
}

export default Subs