import React from 'react'
import './missionvision.css'

const Missionvision = () => {
    return (
        <section className='mv'>
            <div>
                <h1>
                    VISION
                </h1>
                <p>
                    To build a world class consultancy in environmental and food safety through empowerment of
                    young people.
                </p>
            </div>
            <div>
                <h1>
                    MISSION
                </h1>
                <p>
                    To consult for clients on environmental projects like EIA, Environmental Audit, Waste
                    Management, Oil Spill and Bioremediation. To train unemployed youths in agricultural, vocational and entrepreneurial skills in order to
                    ensure sustained food production within the country.
                </p>
            </div>
        </section>
    )
}

export default Missionvision